<script setup lang="ts">
import Footer from './Footer.vue'

defineProps<{
    showSecondOption: boolean
    title?: string
    subtitle?: string
    ctaUrl?: string
    ctaText?: string
    secondOptionUrl?: string
    secondOptionText?: string
}>()
const footerCtaTitle
    = 'We’re here to help you with government relations, research, and advocacy.'
const footerCtaSubtitle
    = 'Civiqa transforms unstructured, fragmented data into actionable intelligence.'
const footerCtaText = 'Let\'s talk'
const footerCtaUrl = '/contact'
const footerCtaSecondOptionText = 'Learn more'
const footerCtaSecondOptionUrl = '/solutions'
</script>

<template>
    <div class="bg-green-50">
        <div
            class="mx-auto max-w-7xl px-6 pb-16 pt-24 sm:py-24 lg:flex lg:items-center lg:justify-between lg:px-8"
        >
            <div class="flex max-w-2xl flex-col">
                <h2
                    class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                >
                    {{ title ?? footerCtaTitle }}
                </h2>
                <h3 v-if="subtitle" class="py-4 text-xl text-gray-600">
                    {{ subtitle ?? footerCtaSubtitle }}
                </h3>
            </div>
            <div
                class="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0"
            >
                <NuxtLink
                    :href="ctaUrl ?? footerCtaUrl"
                    class="rounded-md bg-green-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                    {{ ctaText ?? footerCtaText }}
                </NuxtLink>

                <NuxtLink
                    v-if="showSecondOption"
                    :href="secondOptionUrl ?? footerCtaSecondOptionUrl"
                    class="text-sm font-semibold leading-6 text-gray-900"
                >
                    {{
                        secondOptionText ?? footerCtaSecondOptionText
                    }}
                    &nbsp;<span aria-hidden="true">→</span>
                </NuxtLink>
            </div>
        </div>
        <Footer />
    </div>
</template>
